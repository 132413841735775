import { Grid, Typography } from '@mui/material';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurUnsplashImageCard from '../components/misc/unsplash/OurUnsplashImageCard';

const OurNotFoundPage = () => (
  <OurMarginGridCard margins={{ md: 1, lg: 2 }}>
    <Grid item xs={12} justifyContent="center">
      <Typography variant="h5" paragraph>
        <strong>Something isn't right</strong>
      </Typography>
      <Typography paragraph>We couldn't find the page you asked for.</Typography>
      <Typography paragraph>Here's a random pet picture instead.</Typography>
      <OurUnsplashImageCard />
      <Typography variant="body2">Photo courtesy of Unsplash.</Typography>
    </Grid>
  </OurMarginGridCard>
);

export default OurNotFoundPage;
