import { MemberAvatarMenuItem, useOurAppMemberMenuItems } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';

const useAvatarMenuItem = () => {
  const avatarMenuItems = useOurAppMemberMenuItems().filter(
    ([_text, path]: MemberAvatarMenuItem) => path === webPath.logout,
  );

  return { avatarMenuItems };
};

export default useAvatarMenuItem;
