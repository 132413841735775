import { AirportShuttle, Construction, Plumbing } from '@mui/icons-material';
import { OurVanConsoleIcon, OurVanRepairIcon } from '@petconsole/pure-fe-icon';
import { AppMenuItems, OurAppMenuCollapsable } from '@petconsole/pure-fe-mui';
import {
  useOurSelectAdminMenuVanConsoleMenuOpen,
  useOurSetAdminMenuVanConsoleMenuOpen,
} from '@petconsole/pure-fe-state';
import { webPath } from '@petconsole/pure-shared';

const OurVanConsoleMenu = () => {
  const open = useOurSelectAdminMenuVanConsoleMenuOpen();
  const setOpen = useOurSetAdminMenuVanConsoleMenuOpen();

  const menuItems = [
    [AirportShuttle, 'Van', webPath.adminVans],
    [Construction, 'Van Builder', webPath.adminVanBuilders],
    [OurVanRepairIcon, 'Van Repair', webPath.adminVanRepairs],
    [Plumbing, 'Van Supply', webPath.adminVanSupplies],
  ] as AppMenuItems;

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={OurVanConsoleIcon}
      label="Van Console"
      items={menuItems}
      divider={true}
    />
  );
};

OurVanConsoleMenu.whyDidYouRender = true;

export default OurVanConsoleMenu;
