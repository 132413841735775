import {
  Campaign,
  HealthAndSafety,
  Hearing,
  List as ListIcon,
  Pets,
  Place,
  Storefront,
  Work,
} from '@mui/icons-material';
import {
  OurDogIcon,
  OurDogTrainerIcon,
  OurDogWalkerIcon,
  OurGroomerIcon,
  OurHomeHeartIcon,
  OurPetBowlIcon,
  OurPetConsoleIcon,
} from '@petconsole/pure-fe-icon';
import { AppMenuItems, OurAppMenuCollapsable } from '@petconsole/pure-fe-mui';
import {
  useOurSelectAdminMenuPetConsoleMenuOpen,
  useOurSetAdminMenuPetConsoleMenuOpen,
} from '@petconsole/pure-fe-state';
import { webPath } from '@petconsole/pure-shared';

const OurPetConsoleMenu = () => {
  const open = useOurSelectAdminMenuPetConsoleMenuOpen();
  const setOpen = useOurSetAdminMenuPetConsoleMenuOpen();

  const menuItems = [
    [Storefront, 'Business', webPath.adminBusinesses],
    [OurDogIcon, 'Dog Park', webPath.adminDogParks],
    [OurGroomerIcon, 'Groomer', webPath.adminGroomers],
    [Hearing, 'Found Pet', webPath.adminFoundPets],
    [Campaign, 'Lost Pet', webPath.adminLostPets],
    [Pets, 'Pet', webPath.adminPets],
    [OurPetBowlIcon, 'Pet Food', webPath.adminPetFoods],
    [ListIcon, 'Pet Type', webPath.adminPetTypes],
    [Place, 'Place', webPath.adminPlaces],
    [OurHomeHeartIcon, 'Sitter', webPath.adminSitters],
    [OurDogTrainerIcon, 'Trainer', webPath.adminPetTrainers],
    [HealthAndSafety, 'Vet', webPath.adminVets],
    [OurDogWalkerIcon, 'Walker', webPath.adminPetWalkers],
    [Work, 'Workplace', webPath.adminPetWorkplaces],
  ] as AppMenuItems;

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={OurPetConsoleIcon}
      label="Pet Console"
      items={menuItems}
      divider={true}
    />
  );
};

OurPetConsoleMenu.whyDidYouRender = true;

export default OurPetConsoleMenu;
