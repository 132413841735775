import { ElementType } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  ForgotPassword,
  Login,
  Logout,
  PclTierCancel,
  PclTierPrices,
  PclTierSubscribe,
  PclTierSuccess,
  VerifyCode,
} from '@petconsole/pure-fe-feature';
import { OurExternalPath, OurNotFoundPage, useOurCurrentAdmin, useOurRoutes } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { BusAdmin, BusBuilderAdmin, BusRepairAdmin, BusSupplyAdmin } from '../busConsole';
import {
  BusinessAdmin,
  DogParkAdmin,
  FoundPetAdmin,
  GroomerAdmin,
  LostPetAdmin,
  PetAdmin,
  PetFoodAdmin,
  PetTrainerAdmin,
  PetType,
  PetTypes,
  PetWalkerAdmin,
  PetWorkplaceAdmin,
  PlaceAdmin,
  SitterAdmin,
  VetAdmin,
} from '../petConsole';
import { VanAdmin, VanBuilderAdmin, VanRepairAdmin, VanSupplyAdmin } from '../vanConsole';
import { PclHeader, PclHeaders, PclService, PclServices } from '../pcl';
import { DashboardPage } from '../dashboard';
import { MemberAdmin } from '../memberAdmin';
import { OrderAdmin } from '../orderAdmin';
import { ShopAdmin } from '../shopAdmin';

const AppRoutes = () => {
  const { pathname } = useLocation();
  const { admin } = useOurCurrentAdmin();
  const { unauthRoute, routeTo } = useOurRoutes();

  const adminRoute = (path: string, Element: ElementType) => routeTo(path, admin ? Element : OurNotFoundPage);

  return (
    <Routes>
      {adminRoute(webPath.pclHeaders, PclHeaders)}
      {adminRoute(`${webPath.pclHeaders}/:id`, PclHeader)}
      {adminRoute(webPath.pclServices, PclServices)}
      {adminRoute(`${webPath.pclServices}/:id`, PclService)}
      {adminRoute(webPath.pclTierPrices, PclTierPrices)}
      {adminRoute(`${webPath.pclTierSubscribe}/cancel`, PclTierCancel)}
      {adminRoute(`${webPath.pclTierSubscribe}/success`, PclTierSuccess)}
      {adminRoute(`${webPath.pclTierSubscribe}/:id`, PclTierSubscribe)}
      {routeTo(webPath.logout, Logout)}
      {unauthRoute(webPath.login, Login)}
      {unauthRoute(webPath.forgotPassword, ForgotPassword)}
      {unauthRoute(webPath.verifyCode, VerifyCode)}
      {routeTo(webPath.home, DashboardPage)}
      {adminRoute(webPath.adminBusBuilders, BusBuilderAdmin)}
      {adminRoute(webPath.adminBuses, BusAdmin)}
      {adminRoute(webPath.adminBusinesses, BusinessAdmin)}
      {adminRoute(webPath.adminBusRepairs, BusRepairAdmin)}
      {adminRoute(webPath.adminBusSupplies, BusSupplyAdmin)}
      {adminRoute(webPath.adminDogParks, DogParkAdmin)}
      {adminRoute(webPath.adminGroomers, GroomerAdmin)}
      {adminRoute(webPath.adminFoundPets, FoundPetAdmin)}
      {adminRoute(webPath.adminLostPets, LostPetAdmin)}
      {adminRoute(webPath.adminMembers, MemberAdmin)}
      {adminRoute(webPath.adminOrders, OrderAdmin)}
      {adminRoute(webPath.adminPets, PetAdmin)}
      {adminRoute(webPath.adminPetFoods, PetFoodAdmin)}
      {adminRoute(`${webPath.adminPetTypes}/:id`, PetType)}
      {adminRoute(webPath.adminPetTypes, PetTypes)}
      {adminRoute(webPath.adminPlaces, PlaceAdmin)}
      {adminRoute(webPath.adminShops, ShopAdmin)}
      {adminRoute(webPath.adminSitters, SitterAdmin)}
      {adminRoute(webPath.adminPetTrainers, PetTrainerAdmin)}
      {adminRoute(webPath.adminVans, VanAdmin)}
      {adminRoute(webPath.adminVanBuilders, VanBuilderAdmin)}
      {adminRoute(webPath.adminVanRepairs, VanRepairAdmin)}
      {adminRoute(webPath.adminVanSupplies, VanSupplyAdmin)}
      {adminRoute(webPath.adminVets, VetAdmin)}
      {adminRoute(webPath.adminPetWalkers, PetWalkerAdmin)}
      {adminRoute(webPath.adminPetWorkplaces, PetWorkplaceAdmin)}
      <Route path="/stripe-onboard/*" element={<OurExternalPath path={pathname.replace('/stripe-onboard/', '')} />} />
      {routeTo('*', OurNotFoundPage)}
    </Routes>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
