import { configureStore } from '@reduxjs/toolkit';
import {
  ourAdminMenuReducer,
  ourBusReducer,
  ourBusinessReducer,
  ourBusRepairReducer,
  ourBusSupplyReducer,
  ourCityStatReducer,
  ourCommentReducer,
  ourDogParkReducer,
  ourFeatureReducer,
  ourFeedReducer,
  ourForumReducer,
  ourFoundPetReducer,
  ourFriendReducer,
  ourGroomerReducer,
  ourGuestMenuReducer,
  ourLoggerReducer,
  ourLostPetReducer,
  ourMemberMenuReducer,
  ourMemberReducer,
  ourNotificationReducer,
  ourPetTrainerReducer,
  ourPetWalkerReducer,
  ourPetWorkplaceReducer,
  ourShopReducer,
  ourSnackReducer,
  ourUserReducer,
  ourShopItemVariantReducer,
  ourShopItemReducer,
  ourOrderReducer,
  ourPetReducer,
  ourPetTypeReducer,
  ourPlaceReducer,
  ourVetReducer,
  ourSitterReducer,
  ourBlogReducer,
  ourPostReducer,
  ourTopicReducer,
  ourPclServiceReducer,
  ourPclTierReducer,
  ourPclTierPriceReducer,
  ourVanReducer,
  ourMailFolderReducer,
  ourMailHeaderReducer,
  ourMailMessageReducer,
  ourBusBuilderReducer,
  ourVanBuilderReducer,
  ourVanRepairReducer,
  ourVanSupplyReducer,
  ourPetFoodReducer,
  ourPclHeaderReducer,
} from '@petconsole/pure-fe-state';

export default configureStore({
  reducer: {
    adminMenu: ourAdminMenuReducer,
    blog: ourBlogReducer,
    bus: ourBusReducer,
    busBuilder: ourBusBuilderReducer,
    business: ourBusinessReducer,
    busRepair: ourBusRepairReducer,
    busSupply: ourBusSupplyReducer,
    comment: ourCommentReducer,
    cityStat: ourCityStatReducer,
    dogPark: ourDogParkReducer,
    feature: ourFeatureReducer,
    feed: ourFeedReducer,
    forum: ourForumReducer,
    foundPet: ourFoundPetReducer,
    friend: ourFriendReducer,
    groomer: ourGroomerReducer,
    guestMenu: ourGuestMenuReducer,
    logger: ourLoggerReducer,
    lostPet: ourLostPetReducer,
    mailFolder: ourMailFolderReducer,
    mailHeader: ourMailHeaderReducer,
    mailMessage: ourMailMessageReducer,
    member: ourMemberReducer,
    memberMenu: ourMemberMenuReducer,
    notification: ourNotificationReducer,
    order: ourOrderReducer,
    pclHeader: ourPclHeaderReducer,
    pclService: ourPclServiceReducer,
    pclTier: ourPclTierReducer,
    pclTierPrice: ourPclTierPriceReducer,
    pet: ourPetReducer,
    petFood: ourPetFoodReducer,
    petTrainer: ourPetTrainerReducer,
    petType: ourPetTypeReducer,
    petWalker: ourPetWalkerReducer,
    petWorkplace: ourPetWorkplaceReducer,
    place: ourPlaceReducer,
    post: ourPostReducer,
    shop: ourShopReducer,
    shopItem: ourShopItemReducer,
    shopItemVariant: ourShopItemVariantReducer,
    sitter: ourSitterReducer,
    snack: ourSnackReducer,
    topic: ourTopicReducer,
    user: ourUserReducer,
    van: ourVanReducer,
    vanBuilder: ourVanBuilderReducer,
    vanRepair: ourVanRepairReducer,
    vanSupply: ourVanSupplyReducer,
    vet: ourVetReducer,
  },
});
