import { OurApp } from '@petconsole/pure-fe-mui';
import AppRoutes from './AppRoutes';
import GuestMenuItems from './GuestMenuItems';
import MemberMenuItems from './MemberMenuItems';
import useAvatarMenuItem from './useAvatarMenuItem';

 const App = () => {
  const { avatarMenuItems } = useAvatarMenuItem();

  return (
    <OurApp
      AppRoutes={AppRoutes}
      GuestMenuItems={GuestMenuItems}
      MemberMenuItems={MemberMenuItems}
      avatarMenuItems={avatarMenuItems}
    />
  );
};

App.whyDidYouRender = true;

export default App;