import { ListAlt, ViewList } from '@mui/icons-material';
import { OurPclConsoleIcon } from '@petconsole/pure-fe-icon';
import { AppMenuItems, OurAppMenuCollapsable } from '@petconsole/pure-fe-mui';
import { useOurSelectAdminMenuPclMenuOpen, useOurSetAdminMenuPclMenuOpen } from '@petconsole/pure-fe-state';
import { webPath } from '@petconsole/pure-shared';

const OurPclConsoleMenu = () => {
  const open = useOurSelectAdminMenuPclMenuOpen();
  const setOpen = useOurSetAdminMenuPclMenuOpen();

  const menuItems = [
    [ListAlt, 'Listing Headers', webPath.pclHeaders],
    [ViewList, 'PCL Services', webPath.pclServices, 'Subscription'],
  ] as AppMenuItems;

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={OurPclConsoleIcon}
      label="PCL"
      items={menuItems}
      divider={true}
    />
  );
};

OurPclConsoleMenu.whyDidYouRender = true;

export default OurPclConsoleMenu;
