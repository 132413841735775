import { Divider, List } from '@mui/material';
import { OurAppMenuItem, OurHomeMenuItem } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { Login } from '@mui/icons-material';

const GuestMenuItems = () => {
  return (
    <>
      <OurHomeMenuItem />
      <Divider />
      <List disablePadding>
        <OurAppMenuItem item={[Login, 'Login', webPath.login]} />
      </List>
    </>
  );
};

GuestMenuItems.whyDidYouRender = true;

export default GuestMenuItems;
