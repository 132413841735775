import { Construction, Plumbing } from '@mui/icons-material';
import { OurBusConsole2Icon, OurBusesIcon, OurBusRepairIcon } from '@petconsole/pure-fe-icon';
import { AppMenuItems, OurAppMenuCollapsable } from '@petconsole/pure-fe-mui';
import {
  useOurSelectAdminMenuBusConsoleMenuOpen,
  useOurSetAdminMenuBusConsoleMenuOpen,
} from '@petconsole/pure-fe-state';
import { webPath } from '@petconsole/pure-shared';

const OurBusConsoleMenu = () => {
  const open = useOurSelectAdminMenuBusConsoleMenuOpen();
  const setOpen = useOurSetAdminMenuBusConsoleMenuOpen();

  const menuItems = [
    [OurBusesIcon, 'Bus', webPath.adminBuses],
    [Construction, 'Bus Builder', webPath.adminBusBuilders],
    [OurBusRepairIcon, 'Bus Repair', webPath.adminBusRepairs],
    [Plumbing, 'Bus Supply', webPath.adminBusSupplies],
  ] as AppMenuItems;

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={OurBusConsole2Icon}
      label="Bus Console"
      items={menuItems}
      divider={true}
    />
  );
};

OurBusConsoleMenu.whyDidYouRender = true;

export default OurBusConsoleMenu;
