import { Divider } from '@mui/material';
import { OurHomeMenuItem, OurLogoutMenuItem } from '@petconsole/pure-fe-mui';
import OurBusConsoleMenu from './OurBusConsoleMenu';
import OurMainMenuItems from './OurMainMenuItems';
import OurPclConsoleMenu from './OurPclConsoleMenu';
import OurPetConsoleMenu from './OurPetConsoleMenu';
import OurVanConsoleMenu from './OurVanConsoleMenu';

const MemberMenuItems = () => {
  return (
    <>
      <OurHomeMenuItem />
      <Divider />
      <OurMainMenuItems />
      <OurBusConsoleMenu />
      <OurPetConsoleMenu />
      <OurVanConsoleMenu />
      <OurPclConsoleMenu />
      <Divider />
      <OurLogoutMenuItem />
    </>
  );
};

MemberMenuItems.whyDidYouRender = true;

export default MemberMenuItems;
