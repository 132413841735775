import { Assignment, Group, Store } from '@mui/icons-material';
import { List } from '@mui/material';
import { OurAppMenuItem } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';

const OurMainMenuItems = () => {
  return (
    <List dense disablePadding>
      <OurAppMenuItem item={[Group, 'Member', webPath.adminMembers]} />
      <OurAppMenuItem item={[Assignment, 'Order', webPath.adminOrders]} />
      <OurAppMenuItem item={[Store, 'Shop', webPath.adminShops]} />
    </List>
  );
};

OurMainMenuItems.whyDidYouRender = true;

export default OurMainMenuItems;
