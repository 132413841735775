import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOurPetConsoleIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.371.512C7.203.867 4.367 2.406 2.437 4.816c-.421.528-.707.97-1.078 1.676C.72 7.715.371 8.895.24 10.312c-.028.286-.028 1.43 0 1.688.171 1.688.671 3.227 1.37 4.215.196.277.567.695.75.851.157.13.141.086-.144-.418a11 11 0 0 1-.531-1.085 8.7 8.7 0 0 1-.715-3.532c0-.914.113-1.68.383-2.558.273-.907.746-1.899 1.289-2.707a10 10 0 0 1 2.363-2.442c.246-.172.875-.558 1.25-.761.21-.118.469-.231 1.05-.465.848-.344 1.657-.528 2.81-.645.827-.086 1.195-.078 2.238.04.91.1 1.886.37 2.882.792.536.23 1.54.809 2.008 1.164.528.399 1.051.852 1.281 1.117.704.805.864 1.016 1.176 1.536.809 1.359 1.196 2.68 1.235 4.207a8.96 8.96 0 0 1-1.504 5.14c-.149.227-.48.676-.575.785-.039.047-.19.243-.34.438-.32.422-.394.515-.593.734-.477.524-.883.727-1.723.852-.52.078-.972.11-1.62.11-.688 0-1.227-.04-1.778-.134a2.7 2.7 0 0 1-.703-.195c-.528-.2-.618-.234-.727-.262a.57.57 0 0 0-.324-.007.2.2 0 0 0-.113.168c0 .226.379.64.949 1.046.125.086.23.149.52.309.327.176.452.375.363.562a.4.4 0 0 1-.079.106c-.257.219-1.19.539-1.859.64a4 4 0 0 1-.98.008 4 4 0 0 1-.723-.183 8 8 0 0 1-1.063-.524 19 19 0 0 0-1.945-1.008 3.4 3.4 0 0 0-.996-.21c-.18-.008-.187-.008-.25.03-.078.048-.273.22-.277.247-.008.031.12.219.195.281.074.07.172.117.356.172.296.09.52.184.976.414.543.274.918.5 1.774 1.07.886.586 1.316.82 1.863 1.004.43.149.77.211 1.394.266 1.125.094 1.743.086 2.594-.031 1.09-.153 2.387-.547 3.285-.992a11.5 11.5 0 0 0 2.578-1.739c.254-.23.649-.652.93-.996.367-.445.934-1.27 1.18-1.715l.21-.375c.247-.437.528-1.105.876-2.074.433-1.215.633-2.223.687-3.48.055-1.293-.164-2.723-.59-3.875a12.5 12.5 0 0 0-1.523-2.805 7 7 0 0 0-.527-.633c-.106-.11-.282-.305-.399-.43a4 4 0 0 0-.262-.27c-.03-.023-.109-.093-.175-.151-.524-.5-1.32-1.09-1.961-1.454-1.586-.906-3.16-1.425-4.907-1.625-.25-.027-.351-.027-1.464-.027-.653 0-1.246.008-1.313.016Zm0 0" />
    <path d="M10.207 4.023a4 4 0 0 1-.098.153 1.4 1.4 0 0 0-.171.344c-.118.324-.25.46-.481.503a2 2 0 0 1-.254.016c-.363 0-.703.047-.973.129a5 5 0 0 0-.414.18c-.16.078-.336.152-.39.168-.106.027-.266.03-.864.02-.289-.009-.304-.009-.375.027-.144.066-.21.164-.226.343-.02.211.047.5.164.692.11.18.34.406.523.515.133.078.293.14.633.242.516.153.68.23.844.399.105.101.184.25.223.398.011.051.047.418.078.82.054.712.058.731.086.731.02 0 .304-.101.64-.23q1.348-.523 1.36-.512c.008.012-.332.195-.535.289-.114.05-.391.164-.618.254-.488.187-.593.238-.714.316-.23.157-.278.313-.247.813.051.816.254 1.488.774 2.574.152.316 1.144 2.5 1.242 2.73.117.286.25.626.45 1.157.198.539.222.597.32.738.171.238.496.395.875.418l.144.008-.004-.07c-.004-.04-.066-.704-.136-1.481-.09-.973-.126-1.406-.114-1.406.031 0 .133.082.235.191.203.215.3.512.453 1.344.086.484.093.555.113 1.027.02.496.035.575.16.696.07.074.18.125.32.156.094.02.176.023.465.02.356-.005.446-.005 1.297.003.485.004.633-.008.922-.066.531-.106 1.094-.414 1.645-.902.23-.204.277-.25.53-.56.345-.413.759-1.05.954-1.472a4.4 4.4 0 0 0 .406-1.316c.043-.328.047-.457.02-.824-.07-.871-.207-1.34-.86-2.957-.148-.38-.285-.72-.3-.758a.3.3 0 0 0-.043-.082c-.032-.02-.16.144-.243.316-.117.235-.136.317-.136.57 0 .34.066.575.351 1.254.25.59.344.875.407 1.246.128.762-.055 1.497-.516 2.051-.09.11-.145.157-.145.121 0-.023-.097-.203-.52-.976-.562-1.024-.886-1.5-1.316-1.95a80 80 0 0 0-2.078-2.164c-.152-.148-.629-.62-1.058-1.046-.508-.508-.801-.786-.844-.805a1 1 0 0 0-.367-.086l-.133-.012.008-.078c.008-.039.011-.176.011-.3.004-.215 0-.227-.046-.356a12 12 0 0 0-.29-.64c-.652-1.364-.831-1.88-.917-2.65a3 3 0 0 0-.032-.222.5.5 0 0 0-.164-.097c-.004 0-.02.02-.031.046m0 0" />
    <path d="M8.34 9.848a1.3 1.3 0 0 0-.457.472q-.106.237.074.508c.074.113.106.145.152.156.028.012.032.004.043-.058.004-.04.063-.278.125-.531.149-.567.153-.586.141-.586-.008 0-.04.02-.078.039m-2.414.027c-.024.027-.063.238-.074.387a.4.4 0 0 1-.028.136c-.031.036-.097.055-.246.063-.21.02-.336.062-.703.25a.72.72 0 0 0-.348.297c-.039.055-.086.086-.23.164-.234.125-.328.187-.36.234-.05.078.028.375.153.582.144.239.246.32.539.438.34.14.516.312.613.601.078.227.07.5-.039 1.434-.016.137-.035.332-.047.441-.023.27-.02 1.067.008 1.567.024.355.043 1.086.059 2.093.007.286.023.348.117.442.082.074.316.133.594.14l.101.004.067-.652c.035-.36.07-.77.085-.91.012-.145.024-.266.032-.277.004-.008.031-.012.058-.012h.047l.012.144c.004.082.016.305.023.5.043.914.09 1.23.227 1.442.078.125.191.207.629.472 1.312.793 1.91 1.043 2.75 1.153.265.035.363.031.457-.016a.37.37 0 0 0 .183-.215c.036-.12.032-.394-.007-.949-.043-.601-.059-.754-.137-1.176-.191-1.054-.473-1.988-.856-2.836-.289-.644-.656-1.312-1.32-2.394-.176-.293-.398-.652-.488-.8l-.738-1.169c-.317-.496-.625-.988-.692-1.094a2 2 0 0 0-.152-.234.2.2 0 0 1-.035-.055c0-.015-.2-.215-.22-.215a.05.05 0 0 0-.034.02m-1.262 11.16c-.086.024-.121.043-.273.16l-.13.094.024.047c.05.078.211.219.309.266.07.03.484.128 1.718.402.895.2 1.63.36 1.63.36a.2.2 0 0 0 .015-.06l.008-.05-.363-.168c-.2-.09-.575-.258-.829-.379-1.058-.492-1.535-.66-1.918-.684a.7.7 0 0 0-.19.012Zm.367 1.18a3 3 0 0 0-.39.094c-.059.058.355.214.746.28.27.048.73.071.937.048l.43-.035c.144-.008.266-.024.266-.028s-.028-.015-.063-.02c-.035-.007-.281-.07-.543-.14-.668-.18-.777-.2-1.059-.207-.128-.004-.273 0-.324.008m0 0" />
  </svg>
);
export default SvgOurPetConsoleIcon;
